

























































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VCoinSendModal",
  props: {
    receiverAddress: String
  },
  data() {
    return {
      visible: false,
      coin_type_id: undefined,
      wallet_address: "",
      number: "",
      remarks: ""
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    sendDisabled(): boolean {
      return !this.coin_type_id || !this.wallet_address || !this.number;
    }
  },
  methods: {
    onClick() {
      this.visible = true;
    },
    onSend() {
      dispatch
        .userWalletTransfer({
          coin_type_id: this.coin_type_id,
          wallet_address: this.wallet_address,
          number: parseInt(this.number),
          remarks: this.remarks
        })
        .then(() => {
          this.$message.info("" + this.$t("MiaoCoin.sentSuccessfully"));
          dispatch.userBalance().then(() => {
            this.visible = false;
          });
        });
    }
  },
  watch: {
    receiverAddress() {
      this.wallet_address = this.receiverAddress || "";
      console.log(this.wallet_address);
    }
  },
  mounted() {
    this.wallet_address = this.receiverAddress || "";
  }
});
