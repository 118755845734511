import { RootState, ProductName } from "@/store";
import { paths } from "@/router";

export default {
  allow: (rootState: RootState) => (name: string, action: string) => {
    if (!name || !action) {
      return false;
    }
    const permission = rootState.user.permission.find(
      permission => permission.name === name
    );
    if (!permission) {
      return false;
    }
    const item = permission.items.find(item => item.action === action);
    if (!item) {
      return false;
    }
    return item.status === "allow";
  },
  topProducts: (rootState: RootState) => {
    const isCN = rootState.app.isCN;
    return rootState.app.products
      .filter(product => product[isCN ? "cn_is_top" : "overseas_is_top"])
      .sort(
        (a, b) =>
          b[isCN ? "cn_priority" : "overseas_priority"] -
          a[isCN ? "cn_priority" : "overseas_priority"]
      );
  },
  homeProducts: (rootState: RootState) => {
    const isCN = rootState.app.isCN;
    return rootState.app.products
      .filter(product => product[isCN ? "cn_is_home" : "overseas_is_home"])
      .sort(
        (a, b) =>
          b[isCN ? "cn_priority" : "overseas_priority"] -
          a[isCN ? "cn_priority" : "overseas_priority"]
      );
  },
  homeTopProducts: (rootState: RootState) => {
    const isCN = rootState.app.isCN;
    return rootState.app.products
      .filter(
        product =>
          product[isCN ? "cn_is_home" : "overseas_is_home"] &&
          product[isCN ? "cn_priority" : "overseas_priority"] >= 100
      )
      .sort(
        (a, b) =>
          b[isCN ? "cn_priority" : "overseas_priority"] -
          a[isCN ? "cn_priority" : "overseas_priority"]
      );
  },
  homeBottomProducts: (rootState: RootState) => {
    const isCN = rootState.app.isCN;
    return rootState.app.products
      .filter(
        product =>
          product[isCN ? "cn_is_home" : "overseas_is_home"] &&
          product[isCN ? "cn_priority" : "overseas_priority"] < 99
      )
      .sort(
        (a, b) =>
          b[isCN ? "cn_priority" : "overseas_priority"] -
          a[isCN ? "cn_priority" : "overseas_priority"]
      );
  },
  getStatus: (rootState: RootState) => (name: ProductName) => {
    const product = rootState.app.products.find(
      product => product.code === name
    );
    return (
      (rootState.app.isCN ? product?.cn_status : product?.overseas_status) || ""
    );
  },
  getPath: (rootState: RootState) => (name: ProductName) => {
    const productPaths = {
      CYBERBOARD: paths.cyberboard,
      "CYBERBOARD R2": paths.cyberboardR2,
      "CYBERBOARD R3": paths.cyberboardR3,
      "CYBERBOARD R4": paths.cyberboardR4,
      "CYBERBOARD XMAS": paths.cyberboardXMAS,
      "CYBERBOARD GB": paths.cyberboardGB,
      "CYBERBOARD GLACIER R2": paths.cyberboardGlacierR2,
      "CYBERBOARD TERMINAL": paths.cyberboardTerminal,
      "CYBERBOARD TYPING KIT": paths.cyberboardTypingKit,
      "CYBERBOARD GOLD PAISLEY": paths.cyberboardGoldPaisley,
      "CYBERBOARD NOVEL 2": paths.cyberboardNovel2,
      "THE NOVEL PROJECT": paths.TheNovelProject,
      "COSMO KILLER": paths.cosmoKiller,
      "COSMO KILLER R2": paths.cosmoKillerR2,
      "COSMO KILLER DUNE": paths.cosmoKillerDune,
      "AM 65 LESS": paths.AM65LessEN,
      "AM 65 LESS R2": paths.AM65LessR2,
      "AM 65 LESS FADING": paths.AM65LessFading,
      "AM RGB 65": paths.AMRGB65,
      "AM COMPACT TOUCH SPLIT": paths.home,
      "AM AFA": paths.AMAfa,
      "AM AFA R2": paths.AMAfaR2,
      "AM AFA BLADE": paths.AMAfaBlade,
      "AM HATSU": paths.AMHatsu,
      "AM HATSU BATTLESHIP": paths.AMHatsuBattleship,
      "AM RELIC 80": paths.AMRelic80,
      "AM RELIC 80 OG": paths.AMRelic80OG,
      "AM NEON 80": paths.AMNeon80,
      CYBERMAT: paths.cybermat,
      "CYBERMAT R2": paths.cybermatR2,
      CYBERCHARGE: paths.cybercharge,
      "CYBERCHARGE 35": paths.cybercharge35,
      CYBERCOIN: paths.cybercoin,
      CYBERSWITCH: paths.cyberswitch,
      CYBERBLADE: paths.cyberblade,
      "AM EMPTINESS PHONECASE": paths.AMEmptinessPhonecase,
      "AM EMPTINESS PHONECASE 16": paths.AMEmptinessPhonecase16,
      "AM EMPTINESS CHARGER": paths.AMEmptinessCharger,
      "CARPIO AM EDITON": paths.carpioAMEditon,
      "AM CARPIO G2": paths.AMCarpioG2,
      "AM SILK GLASS": paths.AMSilkGlass,
      "AM INFINITY MOUSE": paths.AMInfinityMouse,
      HOVER: paths.hover,
      "EMERGENCE KEYCAPS": paths.emergenceKeycaps,
      "ICY SILVER SWITCH": paths.icySilverSwitch,
      "ICY SILVER SWITCH PRO": paths.icySilverSwitchPro,
      "ICY SILVER SWITCH PRO V2": paths.icySilverSwitchProV2,
      "GLACIER KEYCAP SET": paths.glacierKeycapSet,
      "ANGRY MIAO 021C": paths.AngryMiao021C,
      "BACK TO THE FUTURE R2": paths.backToTheFutureR2,
      "DANCING CAT": "",
      "THE CHIMNEY CAT": paths.theChimneyCat,
      "TIME MACHINE": paths.timeMachine,
      EXT: paths.ext,
      AMTOYS: rootState.app.isCN
        ? rootState.assets.url.amtoys
        : rootState.assets.url.discord
    };
    return productPaths[name];
  },
  assets: (rootState: RootState) => {
    return rootState.assets;
  }
};
