



































































































































import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, ProductName, Product } from "@/store";

export default Vue.extend({
  name: "VHeader",
  props: {
    title: String,
    name: String as PropType<ProductName>,
    isCollapsedProp: Boolean,
    back: Function,
    backText: String
  },
  data() {
    return {
      isMounted: false,
      isMenuOpen: false,
      isSubMenuOpen: false,
      isCollapsedData: false,
      requestVisible: false,
      requestCount: 0,
      requested: false
    };
  },
  computed: {
    ...mapState({ notifications: "notifications" }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("user", {
      isSignIn: "isSignIn"
    }),
    ...mapGetters({
      getStatus: "getStatus",
      topProducts: "topProducts",
      getPath: "getPath"
    }),
    isCollapsed(): boolean {
      if (this.isMounted) {
        const { offsetHeight } = this.$refs.bottom as HTMLElement;
        if (offsetHeight === 0) {
          return false;
        } else {
          return this.isCollapsedProp || this.isCollapsedData;
        }
      }
      return false;
    },
    buyText(): string {
      const status = this.getStatus(this.name);
      if (status === "restock") {
        return this.$t("preorder") + "";
      } else if (status === "GB") {
        return this.$t("preorder") + "";
      } else if (status === "inStock") {
        return this.$t("buy") + "";
      }
      return "";
    },
    requestText(): string {
      if (this.requested) {
        if (this.requestCount < 1000) {
          return this.requestCount + " " + this.$t("request.requests");
        } else {
          return (
            (this.requestCount / 1000).toFixed(1) +
            "k " +
            this.$t("request.requests")
          );
        }
      }
      return this.$t("request.request") + "";
    }
  },
  methods: {
    onItemClick(path: string) {
      this.$router.push(path);
      this.isMenuOpen = false;
    },
    onProductClick({ code, cn_url, overseas_url }: Product) {
      if (this.getStatus(code) === "jump") {
        window.open(this.isCN ? cn_url : overseas_url, "_blank");
      } else {
        dispatch.learnMore(code);
      }
    },
    openMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    openSubMenu() {
      this.isSubMenuOpen = !this.isSubMenuOpen;
    },
    checkCollapse() {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop <= 0) {
        this.isCollapsedData = false;
      } else if (!this.isCollapsedData) {
        this.isCollapsedData = true;
      }
    },
    onShoppingCart() {
      dispatch.orderNow();
      this.isMenuOpen = false;
    },
    onUserCenter() {
      if (this.isSignIn) {
        this.$router.push(this.$paths.userCenter);
      } else {
        dispatch.userSignIn();
      }
      this.isMenuOpen = false;
    },
    onSignOut() {
      dispatch.userSignOut();
      this.isMenuOpen = false;
    },
    onBack() {
      this.$router.back();
    },
    onBuy() {
      dispatch.orderNow(this.name);
    },
    getRequestCount() {
      if (this.name) {
        dispatch
          .requestCount(this.name)
          .then(res => {
            this.requestCount = res.count;
            this.requested = res.has_collection;
          })
          .catch(() => {
            this.$message.destroy();
          });
      }
    },
    onRequest() {
      if (this.isSignIn) {
        this.requestVisible = true;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            request: "true"
          }
        });
      } else {
        dispatch.userSignIn();
      }
    },
    onRequestCancel() {
      this.getRequestCount();
      this.requestVisible = false;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          request: ""
        }
      });
    },
    onFollowUs() {
      dispatch.followUs(this.name);
    },
    goTask(id: number) {
      if (this.isMobile) {
        this.$router.push(this.$paths.dao + id + "/");
      } else {
        window.open(this.$paths.dao + id + "/", "_blank");
      }
    },
    goDryStudio() {
      window.open("https://dry---studio.com/", "_blank");
    }
  },
  mounted() {
    const { request } = this.$route.query;
    if (request) setTimeout(this.onRequest, 1000);
    this.isMounted = true;
    window.addEventListener("scroll", this.checkCollapse);
    this.getRequestCount();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkCollapse);
  }
});
